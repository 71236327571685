'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.clipboard === 'undefined') {
    window.gcls.clipboard = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var selectorCopy = '[data-gcls-action="clipboard-copy"]';
  var selectorIconCopied = '.clipboard-copied';
  var selectorIconEmpty = '.clipboard-empty';
  var selectorIcons = '[data-gcls-id="clipboard-icons"]';
  var selectorInit = '[data-gcls-init-clipboard="1"]';

  //
  // Helper functions
  //

  function clearSelectedText() {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection && document.selection.empty) {
      // IE
      document.selection.empty();
    }
  }

  function getIconCopied($container) {
    var $icons = $container.find(selectorIcons);
    return $icons.find(selectorIconCopied);
  }

  function getIconEmpty($container) {
    var $icons = $container.find(selectorIcons);
    return $icons.find(selectorIconEmpty);
  }

  function resetClipboardIcons($container) {
    var $copyTriggers = $(selectorCopy);
    var $iconEmpty = getIconEmpty($container);
    $copyTriggers.html($iconEmpty.get(0).outerHTML);
  }

  //
  // Init functions
  //

  function initModalEvents($modal) {
    $modal.on('hide.bs.modal', function () {
      clearSelectedText();
    });

    $modal.on('hidden.bs.modal', function () {
      resetClipboardIcons($modal);
    });
  }

  function initClipboard($container) {
    if ($container.length !== 1) {
      return;
    }

    /* eslint-disable no-new */
    var clipboard = new ClipboardJS(selectorCopy, {
      container: $container.get(0)
    });
    /* eslint-enable no-new */

    clipboard.on('success', function (e) {
      resetClipboardIcons($container);
      var $trigger = $(e.trigger);
      var $iconCopied = getIconCopied($container);
      $trigger.html($iconCopied.get(0).outerHTML);
    });

    if ($container.hasClass('modal')) {
      initModalEvents($container);
    }
  }
  gcls.clipboard.init = initClipboard;

  $(window).on('load', function () {
    var $modal = $(selectorInit);
    initClipboard($modal);
  });
}(jQuery));
